/* Adicionando suporte ao dark mode */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-light: #ffffff;
  --bg-dark: #111827;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply transition-colors duration-300;
}

/* Dark mode */
html.dark body {
  background-color: var(--bg-dark);
  color: #ffffff;
}

/* Remover contorno azul/lilás ao clicar em imagens ou links */
img:focus,
img:active,
a:focus,
a:active {
  outline: none;
  box-shadow: none;
}

/* Ajuste geral para evitar contornos indesejados */
*:focus {
  outline: none;
  box-shadow: none;
}
